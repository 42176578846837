import {
  TextField,
  Autocomplete,
  CardMedia,
  Typography,
  Popper,
  ListItem,
  ListItemButton,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ListItemButtonProps } from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { ElementType } from 'react';
import { matchPath } from 'react-router';
import { useLocation, Link } from 'react-router-dom';

import { queries } from 'queries';
import { find, get, split } from 'vendor/lodash';

import { useChannelRoutes } from '../hooks/useChannelRoutes';

const StyledPopper = styled(Popper)`
  width: 450px !important;
`;

function ChannelPopper(props) {
  return <StyledPopper {...props} placement="bottom-end" />;
}

const ChannelListItem = styled(ListItem)`
  padding: 0px !important;
  border-radius: 8px;
`;

const ChannelListOption = styled(ListItemButton)<
  ListItemButtonProps & { component: ElementType; to: string }
>`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px;
  height: 64px;
  border-radius: 8px;

  &:hover {
    background-color: unset;
    color: unset;
  }
`;

function useGetCurrentChannelId() {
  const location = useLocation();

  const match = matchPath(location.pathname, {
    path: '/a/channels/:public_id_and_slug',
  });

  if (!match) {
    return '';
  }

  const publicIdAndSlug = get(match, 'params.public_id_and_slug');

  if (!publicIdAndSlug) {
    return '';
  }

  const publicId = split(publicIdAndSlug, '_')[0];
  return publicId;
}

export function ChannelSelector() {
  const { detail: detailRoute } = useChannelRoutes();
  const currentChannelId = useGetCurrentChannelId();

  const { data: channelsData, isLoading: isLoadingChannels } = useQuery({
    ...queries.channels.list('view_mode=lite'),
  });

  const channelOptions = get(channelsData, 'results', []);

  const currentChannel = find(channelOptions, { public_id: currentChannelId });

  return (
    <Autocomplete
      id="channel-selector"
      value={currentChannel || null}
      options={channelOptions}
      loading={isLoadingChannels}
      isOptionEqualToValue={(option, value) => option.public_id === value.public_id}
      getOptionLabel={(option) => option.name}
      disableClearable
      sx={{ width: 258 }}
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          label="Channels"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoadingChannels ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      componentsProps={{
        paper: {
          sx: {
            padding: '15px',
          },
        },
      }}
      PopperComponent={ChannelPopper}
      ListboxProps={{
        style: {
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          padding: 0,
        },
      }}
      renderOption={(props, option) => {
        return (
          <ChannelListItem {...props} key={option.public_id}>
            <ChannelListOption
              disableGutters
              disableRipple
              component={Link}
              to={detailRoute({ public_id_and_slug: option.public_id_and_slug })}
            >
              <CardMedia
                component="img"
                sx={{ width: 72, height: 44, objectFit: 'cover', borderRadius: 1 }}
                image={option.cover}
                alt={option.name}
              />
              <Typography variant="body1" component="span">
                {option.name}
              </Typography>
            </ChannelListOption>
          </ChannelListItem>
        );
      }}
    />
  );
}
