import { Box, alpha } from '@mui/material';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { ChannelSelector } from 'features/channels/components/ChannelSelector';
import ProfileMenu from 'navigation/components/ProfileMenu';
import SupportMenu from 'navigation/components/SupportMenu';
import { getBestMatchURL } from 'scenes/NavBar/navbar-services';
import colors from 'services/colors';
import { METRICS_ACTIVITIES, useMetrics } from 'services/metrics';
import { mapRoute } from 'services/requests';
import Clicker from 'shared/components/Clicker';
import Icon from 'shared/components/Icon';
import MediaPoint from 'shared/components/MediaPoint';
import Pill from 'shared/components/Pill/Pill';
import { Sidebar } from 'shared/components/Sidebar';
import Text from 'shared/components/Text';
import { useCurrentUser, usePreviousLocation, useToggles } from 'shared/hooks';
import { get, isEqual, map, pick } from 'vendor/lodash';

import './style.scss';

const AppsContainer = styled.div`
  display: none;

  ${MediaPoint.DesktopSm} {
    display: inline-flex;
  }
`;

const AppsList = styled.ul`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
`;

const AppsListItem = styled.li`
  display: inline-flex;
  height: 100%;
`;

const CustomerLogo = () => {
  const { customer_logo: logo } = useCurrentUser();

  if (!logo) return null;

  return (
    <a href="/">
      <img className="navbar-brand-logo" alt="PlusPlus logo" style={{ padding: 0 }} src={logo} />
    </a>
  );
};

const LinkWrapper = (props) => {
  const anchorProps = pick(props, ['children', 'className', 'target']);
  const componentProps = pick(props, ['onClick']);
  const { url, to } = props;
  if (url) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a href={url} {...anchorProps} {...componentProps} />;
  }
  return <Link to={to} {...anchorProps} {...componentProps} />;
};

LinkWrapper.propTypes = {
  to: PropTypes.string,
  url: PropTypes.string,
};

const SidebarNavItem = styled(LinkWrapper)`
  padding: 8px 12px;
  display: flex;
  align-items: center;

  ${({ isSelected }) =>
    isSelected
      ? `
    &, &:hover, &:focus {
      color: ${colors.emphasis600TextColor};
      background-color: ${colors.emphasis600};
    }
  `
      : `
    &, &:hover, &:focus {
      color: ${colors.neutral900};
    }
  `}
`;

const AppSelector = styled(LinkWrapper)`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  padding: 8px 16px;
  line-height: 160%;

  ${({ isSelected }) =>
    isSelected
      ? `
    color: ${colors.action700} !important;
    background-color: ${alpha(colors.action700, 0.12)};
    font-weight: bold;
  `
      : `
    color: ${alpha('#000000', 0.6)} !important;

    &:hover,&:active,:focus {
      color: ${colors.action800} !important;
      background-color: ${colors.neutral50};
    }
  `}
`;

const PillWrapper = styled.div`
  margin-left: 4px;
`;

const NavBarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  padding: 0px 24px;
  border-radius: 0;

  // Removes some styling from other classes
  &:before,
  &:after {
    content: none;
  }
`;

const MobileAppsContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const SidebarContent = styled.header`
  display: flex;
  flex-direction: column;
`;

const SidebarContentHeader = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  border-bottom: 1px solid ${colors.neutral100};
`;

// This component has a conditional because we can add more types of pills in the future
const NavBarPill = ({ pillType }) => {
  if (pillType === 'new') {
    return (
      <PillWrapper>
        <Pill variant="primary" label="NEW" size="small" />
      </PillWrapper>
    );
  }
  return null;
};

const routeMapping = {
  events: 'eventsList',
  snippet: 'standAloneArticleDetail',
  programSessions: 'programDetails',
  mentorship: 'mentorList',
  people: 'peopleList',
  unified_catalog: 'unifiedCatalogList',
  my_upcoming: 'myUpcoming',
  my_hub: 'myHub',
};

NavBarPill.propTypes = {
  pillType: PropTypes.string,
};

export const NavBar = (props) => {
  const { navigationMenuTabs, detailedObject, detailedObjectType, usesDetailedObject } = props;

  const { toggle_channels: toggleChannels } = useToggles();

  const { trackActivity } = useMetrics();
  const currentLocation = useLocation();
  const lastLocationWithRedirectAllowed = usePreviousLocation();
  const [lastLocation, setLastLocation] = React.useState(lastLocationWithRedirectAllowed);
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  React.useEffect(() => {
    // Do not change the last location when there is a redirection to the same page.
    if (
      isEqual(get(lastLocationWithRedirectAllowed, 'pathname'), get(currentLocation, 'pathname')) &&
      isEqual(get(lastLocationWithRedirectAllowed, 'search'), get(currentLocation, 'search'))
    )
      return;

    setLastLocation(lastLocationWithRedirectAllowed);
  }, [lastLocationWithRedirectAllowed]);

  React.useEffect(() => {
    // Closes sidebar after clicking on navigation
    setIsSidebarOpen(false);
  }, [currentLocation]);

  const selectedTabIndex = getBestMatchURL({
    lastLocationQuerystring: get(lastLocation, 'search'),
    currentLocationUrl: get(currentLocation, 'pathname'),
    currentLocationQuerystring: queryString.parse(get(currentLocation, 'search')),
    tabConfig: navigationMenuTabs,
    detailedObject,
    detailedObjectType,
    usesDetailedObject,
    currentSearch: get(currentLocation, 'search'),
  });

  const processedTabs = map(navigationMenuTabs, (tabConfig) => {
    const { module, id } = tabConfig;
    const querystring = `?${get(tabConfig, 'querystring', '')}`;

    let route = '';
    if (module === 'snippet' || module === 'programSessions') {
      route = `${mapRoute(routeMapping[module], { public_id_and_slug: id })}${querystring}`;
    } else if (routeMapping[module]) {
      route = `${mapRoute(routeMapping[module], { ...(id && { id }) })}${querystring}`;
    }

    return {
      ...tabConfig,
      route,
    };
  });

  return (
    <NavBarContainer className="navbar plusplus-navbar plusplus-navbar-logged">
      <MediaQuery maxWidth={1023}>
        <MobileAppsContainer>
          <Clicker
            padding="8px"
            onClick={() => setIsSidebarOpen(true)}
            aria-label="Open navigation"
          >
            <Icon name="hamburger" height={16} width={16} color={colors.neutral600} />
          </Clicker>
          <Sidebar
            isOpen={isSidebarOpen}
            width={window.innerWidth < 550 ? window.innerWidth : 360}
            onChange={({ isOpen }) => setIsSidebarOpen(isOpen)}
          >
            {/* div needed because sidebar adds inline style at first child */}
            <div>
              <SidebarContent>
                <SidebarContentHeader>
                  <Clicker
                    padding="16px"
                    onClick={() => setIsSidebarOpen(false)}
                    aria-label="Close navigation"
                  >
                    <Icon name="close" height={16} width={16} color={colors.neutral600} />
                  </Clicker>
                </SidebarContentHeader>
                {map(processedTabs, ({ label, route, pill, url, target }, i) => {
                  return (
                    <SidebarNavItem
                      key={url ?? route}
                      target={target}
                      url={url}
                      isSelected={i === selectedTabIndex}
                      to={route}
                    >
                      <Text size="h3">{label}</Text>
                      <NavBarPill pillType={pill} />
                    </SidebarNavItem>
                  );
                })}
              </SidebarContent>
            </div>
          </Sidebar>
        </MobileAppsContainer>
      </MediaQuery>

      <Box display="flex" gap={3} flex={1}>
        <CustomerLogo />
        <AppsContainer>
          <AppsList>
            {map(processedTabs, ({ label, route, pill, url, target }, i) => {
              return (
                <AppsListItem key={url ?? route}>
                  <AppSelector
                    key={url ?? route}
                    target={target}
                    url={url}
                    isSelected={i === selectedTabIndex}
                    to={route}
                    onClick={() =>
                      trackActivity(METRICS_ACTIVITIES.TAB_CLICK, {
                        url: url || route,
                      })
                    }
                  >
                    {label}
                    <NavBarPill pillType={pill} />
                  </AppSelector>
                </AppsListItem>
              );
            })}
          </AppsList>
        </AppsContainer>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="flex-end" gap={3}>
        {toggleChannels && (
          <Box
            sx={{
              display: 'none',
              [`${MediaPoint.DesktopSm}`]: {
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            <ChannelSelector />
          </Box>
        )}
        <Box display="flex" alignItems="center" gap={2}>
          <SupportMenu />
          <ProfileMenu />
        </Box>
      </Box>
    </NavBarContainer>
  );
};

NavBar.propTypes = {
  navigationMenuTabs: PropTypes.array,
  detailedObject: PropTypes.object,
  detailedObjectType: PropTypes.any,
  usesDetailedObject: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  detailedObject: get(state, 'shared.detailedObject'),
  detailedObjectType: get(state, 'shared.detailedObjectType'),
  usesDetailedObject: get(state, 'shared.usesDetailedObject'),
  currentUser: get(state, 'user.currentUser'),
  navigationMenuTabs: get(state, 'user.currentUser.tabs_configuration', []),
});

export default connect(mapStateToProps)(NavBar);
